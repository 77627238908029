import { LZSPARouter } from "@eventbuilder-utils/lzspa";
import PubSubClient from "@eventbuilder-utils/pubsub-client";
import { Modal } from "../modals";

class EBSessionHandler extends EventTarget {
    #baseUrl = null;
    #accessToken = null;
    #accessTokenExpires = null;
    #refreshToken = null;
    #refreshTokenExpires = null;
    #me = null;
    #tenant = null;

    #inactivityWarningTimer;
    #inactivityWarningTimout = 900000;
    #inactivityWarningModal;
    #inactivityLogoutTimer;
    #inactivityLogoutTimout = 60000;

    get account() { return this.#me.data; }
    get tenant() { return this.#tenant; }
    get valid() { return this.#me != null; }
    get accessToken() { return this.#accessToken; }
    get refreshToken() { return this.#refreshToken; }

    constructor() {
        super();
        this.#baseUrl = window.location.origin;
        this.#accessToken = sessionStorage.getItem("at") || null;
        this.#accessTokenExpires = sessionStorage.getItem("ate") || null;
        this.#refreshToken = sessionStorage.getItem("rt") || null;
        this.#refreshTokenExpires = sessionStorage.getItem("rte") || null;
        this.#inactivityWarningModal = new Modal({
            title: "Inactivity",
            size: "sm",
            static: true,
            content: "You've been inactive for awhile. Click 'OK' to stay logged in.",
            controls: [
                { action: "close", label: "OK", ariaLabel: "Ok" }
            ]
        })
        .on("eb:modal.close.click", (event) => { this.resetInactivityTimer(); event.target.hide(); })
        .on("eb:modal.control.close.click", (event) => { this.resetInactivityTimer(); event.target.hide(); });
        /*setInterval(() => {
            if (this.#accessToken == null || this.#accessTokenExpires == null) return;
            if (this.#accessTokenExpires > (Date.now() - 1200000)) return;
            this.get("/api/v3/me").catch(ex => console.error(ex));
        }, 900000);*/
    }

    resetInactivityTimer() {
        clearTimeout(this.#inactivityWarningTimer);
        clearTimeout(this.#inactivityLogoutTimer);
        if (this.#accessToken == null) return;
        this.#inactivityWarningTimer = setTimeout(() => { this.#inactivityWarningCallback(); }, this.#inactivityWarningTimout);
    }

    #inactivityWarningCallback() {
        if (this.#accessToken == null) return;
        this.#inactivityWarningModal.show();
        this.#inactivityLogoutTimer = setTimeout(() => { this.#inactivityLogoutCallback(); }, this.#inactivityLogoutTimout);
    }

    #inactivityLogoutCallback() {
        if (this.#accessToken == null) return;
        this.logout();
        this.resetInactivityTimer();
        this.#inactivityWarningModal.hide();
        alert("You've been logged out for inactivity.");
    }

    on(type, callback, options) {
        this.addEventListener(type, callback, options);
        return this;
    }

    async initialize() {
        if (new URL(window.location.href).searchParams.has("ak")) {
            const response = await fetch(new URL("/api/v3/token", this.#baseUrl), {
                method: "POST",
                headers: {
                    Authorization: `EBAccountKey ${new URL(window.location.href).searchParams.get("ak")}`
                }
            });
            if (response.status != 201) {
                try { this.logout(); } catch (ex) { console.error(ex); }
                console.error((await response.text()));
                throw new Error("session has expired", { cause: response });
            }
            const data = await response.json();
            this.#accessToken = data.accessToken;
            this.#accessTokenExpires = data.expires;
        }
        if (this.#accessToken) {
            try {
                this.#me = await this.query(`/api/v3/me`);
                this.#tenant = (await this.query(`/api/v3/tenants?id=${this.account.tenants[localStorage.getItem("lt") || 0].id}&offset=0&limit=1`)).data[0];
                this.#tenant.permissions = this.account.tenants.find(a => a.id == this.#tenant.id).permissions;

                if (!this.#tenant.setupWizardCompleted.value) {
                    const component = await import("../components/tenant-setup");
                    new component.default({ data: this.#tenant }).initialize();
                }

                if (!this.account.setupComplete) {
                    const component = await import("../components/account-setup");
                    new component.default({ data: this.account }).initialize();
                }
                
                this.dispatchEvent(new CustomEvent("eb:session.login"));
                this.dispatchEvent(new CustomEvent("eb:session.tenantchange"));
                this.resetInactivityTimer();

                PubSubClient.connect(this.#me._links.realtime);
            } catch(ex) {
                console.error(ex);
                this.logout();
            }
        }
        this.dispatchEvent(new CustomEvent("eb:session.initialized"));
    }

    async changeTenant(tenantId) {
        if (this.account.tenants.find(a => a.id == tenantId) == undefined) throw new Error("Invalid Tenant");

        this.#tenant = (await this.query(`/api/v3/tenants?id=${tenantId}&offset=0&limit=1`)).data[0];
        this.#tenant.permissions = this.account.tenants.find(a => a.id == this.#tenant.id).permissions;

        if (!this.#tenant.setupWizardCompleted.value) {
            const component = await import("../components/tenant-setup");
            new component.default({ data: this.#tenant }).initialize();
            //new TenantSetupWizardModal(this.#tenant).show();
        }

        this.dispatchEvent(new CustomEvent("eb:session.tenantchange"));
    }

    async login(email, password, otp = null) {
        this.dispatchEvent(new CustomEvent("eb:session.requeststart"));

        try {
            const response = await fetch(new URL("/api/v3/login", this.#baseUrl), {
                method: "POST",
                cache: "no-cache",
                body: JSON.stringify({
                    email: email,
                    password: password,
                    otp: otp
                })
            });
    
            if (response.status == 401) throw new Error((await response.text()) || "invalid credentials", { cause: response });
            if (response.status != 201) throw new Error((await response.text()) || response.statusText, { cause: response });
    
            const data = await response.json();

            this.#accessToken = data.accessToken;
            sessionStorage.setItem("at", this.#accessToken);
            this.#accessTokenExpires = data.accessTokenExpires;
            sessionStorage.setItem("ate", this.#accessTokenExpires);
            this.#refreshToken = data.refreshToken;
            sessionStorage.setItem("rt", this.#refreshToken);
            this.#refreshTokenExpires = data.refreshTokenExpires;
            sessionStorage.setItem("rte", this.#refreshTokenExpires);
            this.#me = await this.query(`/api/v3/me`);
            this.#tenant = (await this.query(`/api/v3/tenants?id=${this.account.tenants[localStorage.getItem("lt") || 0].id}&offset=0&limit=1`)).data[0];
            this.#tenant.permissions = this.account.tenants.find(a => a.id == this.#tenant.id).permissions;

            if (!this.#tenant.setupWizardCompleted.value) {
                const component = await import("../components/tenant-setup");
                new component.default({ data: this.#tenant }).initialize();
                //new TenantSetupWizardModal(this.#tenant).show();
            }

            this.dispatchEvent(new CustomEvent("eb:session.login"));
            this.dispatchEvent(new CustomEvent("eb:session.tenantchange"));
            this.resetInactivityTimer();

            PubSubClient.connect(this.#me._links.realtime);
            return data;
        } catch (error) {
            throw error;
        } finally {
            this.dispatchEvent(new CustomEvent("eb:session.requestend"));
        }
    }

    async logout() {
        try {
            if (this.#accessToken != null) {
                await fetch(new URL("/api/v3/logout", this.#baseUrl), {
                    method: "POST",
                    cache: "no-cache",
                    headers: {
                        "Authorization": `Bearer ${this.#accessToken}`
                    },
                    body: JSON.stringify({})
                });
            }
        } catch (error) {
            console.error(error);
        } finally {
            this.#accessToken = null;
            this.#accessTokenExpires = null;
            sessionStorage.removeItem("at");
            sessionStorage.removeItem("ate");
            this.#refreshToken = null;
            this.#refreshTokenExpires = null;
            sessionStorage.removeItem("rt");
            sessionStorage.removeItem("rte");
            this.#me = null;
            this.#tenant = null;
            this.resetInactivityTimer();
            PubSubClient.disconnect();
            this.dispatchEvent(new CustomEvent("eb:session.logout"));
        }
    }
    
    async recoverPassword(email) {
        this.dispatchEvent(new CustomEvent("eb:session.requeststart"));
        try {
            const response = await fetch(new URL("/api/v3/me/recover", this.#baseUrl), {
                method: "POST",
                cache: "no-cache",
                body: JSON.stringify({
                    type: "account",
                    data: { email: email }
                })
            });
    
            if (response.status >= 400) throw new Error((await response.text()) || response.statusText, { cause: response });
            if (response.status == 204) return response.text();
            if (response.status == 205) return null;

            return (await response.json());
        } catch (error) {
            throw error;
        } finally {
            this.dispatchEvent(new CustomEvent("eb:session.requestend"));
        }
    }

    async query(opts) {
        if (typeof opts == "string") opts = { url: opts };
        
        if (opts.fireRequestEvent != false) this.dispatchEvent(new CustomEvent("eb:session.requeststart"));

        try {
            var response = await this.#query(opts);
            if (response.status >= 500) {
                throw new Error((await response.text()) || response.statusText, { cause: response });
            } else if (response.status >= 400) {
                if (response.status == 440) {
                    this.logout();
                    LZSPARouter.navigate("/login");
                }
                throw new Error((await response.text()) || response.statusText, { cause: response });
            } else if (response.status == 204) {
                return response.text();
            } else if (response.status == 205) {
                return null;
            } else {
                return (await response.json());
            }
        } catch (error) {
            //if (error.message) showErrorToast(error.message);
            throw error;
        } finally {
            if (opts.fireRequestEvent != false) this.dispatchEvent(new CustomEvent("eb:session.requestend"));
        }
    }

    async #query(opts) {
        return await fetch(new URL(opts.url, this.#baseUrl), {
            method: opts.method || "GET",
            headers: {
                ...(opts.headers || {}),
                "Authorization": `Bearer ${this.#accessToken}`,
                "EB-Tenant": this.tenant?.id || ""
            },
            ...(opts.body == undefined ? {} : { body: opts.raw == true ? opts.body : JSON.stringify(opts.body) })
        });
    }

    async post(url, data) { return this.query({ url: url, method: "POST", body: data }); }

    async create(url, data) { return this.post(url, data); }

    async get(url) { return this.query({ url: url }); }

    async put(url, data) { return this.query({ url: url, method: "PUT", body: data }); }

    async update(url, data) { return this.put(url, data); }

    async patch(url, data) { return this.query({ url: url, method: "PATCH", body: data }); }

    async partialUpdate(url, data) { this.patch(url, data); }

    async delete(url) { return this.query({ url: url, method: "DELETE" }); }

    async upload(url, data) { return this.query({ url: url, method: "POST", headers: { "Content-Type": "application/octet-stream" }, body: data, raw: true, fireRequestEvent: false }); }
}

export const EBSession = new EBSessionHandler();