import { LZSPARouter } from "@eventbuilder-utils/lzspa";
import EBPubSubClient from "@eventbuilder-utils/pubsub-client";
import "external-svg-loader";

import { EBSession } from "./utils/session";
import "./scss/styles.scss";

import "./elements/dropdown-button.js";
import "./elements/datetime-picker.js";
import { Modal } from "./modals/index.js";

var activeAPICalls = 0;

function setColorScheme(mode) {
    if (mode == "dark") {
        document.body.classList.add("dark");
        document.querySelector("#darkModeToggle").checked = true;
    } else {
        document.body.classList.remove("dark");
        document.querySelector("#darkModeToggle").checked = false;
    }
    window.localStorage.setItem("scheme", mode);
}

if (window.localStorage.getItem("scheme")) {
    setColorScheme(window.localStorage.getItem("scheme"));
} else if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    setColorScheme("dark");
}

window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => setColorScheme(event.matches ? "dark" : "light"));

document.querySelector("#darkModeToggle").addEventListener("click", (event) => {
    setColorScheme(document.body.classList.contains("dark") ? "light" : "dark");
});

document.querySelectorAll(".topbar > .topbar__content > button[aria-controls]").forEach(element => {
    element.addEventListener("click", (event) => {
        const element = document.getElementById(event.target.getAttribute("aria-controls"));
        element.style.display = element.style.display == "block" ? "none" : "block";
    });
});

document.addEventListener("mousemove", (event) => { EBSession.resetInactivityTimer(); }, { capture: true, passive: true });
document.addEventListener("mousedown", (event) => { EBSession.resetInactivityTimer(); return true; }, { capture: true, passive: true });
document.addEventListener("touchstart", (event) => { EBSession.resetInactivityTimer(); return true; }, { capture: true, passive: true });
document.addEventListener("click", (event) => { EBSession.resetInactivityTimer(); return true; }, { capture: true, passive: true });
document.addEventListener("keypress", (event) => { EBSession.resetInactivityTimer(); return true; }, { capture: true, passive: true }); 

document.addEventListener("click", (event) => {
    //Auto-hide more menu in tinymce
    for (let el of document.querySelectorAll("div.tox-toolbar__overflow")) {
        if (el.contains(event.target) == false) document.querySelector(`button[aria-controls="${el.parentElement.id}"]`).click();
    }

    if (
        document.querySelector("#sidebar").style.display == "none" &&
        document.querySelector("#usermenu").style.display == "none"
    ) return;
    if (
        event.target.closest("#sidebar") != null ||
        event.target.closest("#usermenu") != null ||
        event.target.closest(".topbar") != null
    ) return;
    document.querySelector("#sidebar").style.display = "none";
    document.querySelector("#usermenu").style.display = "none";
});

document.addEventListener('focusin', function (e) { 
    if (e.target.closest('.tox-tinymce-aux, .moxman_window, .tam-assetmanager-root') !== null) { e.stopImmediatePropagation(); } 
    if (e.relatedTarget?.classList?.contains("wysiwyg")) { e.stopImmediatePropagation(); }
});

EBPubSubClient.on("message", (group, message) => {
    console.log(`${group} => ${message}`);
});

EBSession
    .on("eb:session.requeststart", () => {
        activeAPICalls++;
        document.getElementById("loadingSpinner").style.visibility = (activeAPICalls > 0 ? "visible" : "hidden");
    })
    .on("eb:session.requestend", () => {
        activeAPICalls--;
        document.getElementById("loadingSpinner").style.visibility = (activeAPICalls > 0 ? "visible" : "hidden");
    })
    .on("eb:session.tenantchange", () => {
        //document.getElementById("indexPortalName").innerText = Session.tenant.name;
        if (EBSession.account.tenants.find(a => a.id == EBSession.tenant.id).permissions.platformAccountsRead == true)
            document.body.querySelector(`div.app__wrapper > div.sidebar > nav.sidebar__bar > a[href="/accounts"]`)?.style?.removeProperty("display");
        else
            document.body.querySelector(`div.app__wrapper > div.sidebar > nav.sidebar__bar > a[href="/accounts"]`)?.style?.setProperty("display", "none");
        
        if (EBSession.account.tenants.find(a => a.id == EBSession.tenant.id).permissions.platformTasksRead == true)
            document.body.querySelector(`div.app__wrapper > div.sidebar > nav.sidebar__bar > a[href="/tasks"]`)?.style?.removeProperty("display");
        else
            document.body.querySelector(`div.app__wrapper > div.sidebar > nav.sidebar__bar > a[href="/tasks"]`)?.style?.setProperty("display", "none");

        if (EBSession.account.tenants.find(a => a.id == EBSession.tenant.id).permissions.platformRegistrantsRead == true)
            document.body.querySelector(`div.app__wrapper > div.sidebar > nav.sidebar__bar > a[href="/registrants"]`)?.style?.removeProperty("display");
        else
            document.body.querySelector(`div.app__wrapper > div.sidebar > nav.sidebar__bar > a[href="/registrants"]`)?.style?.setProperty("display", "none");

        if (EBSession.account.tenants.find(a => a.id == EBSession.tenant.id).permissions.platformSubscriptionsRead == true)
            document.body.querySelector(`div.app__wrapper > div.sidebar > nav.sidebar__bar > a[href="/subscriptions"]`)?.style?.removeProperty("display");
        else
            document.body.querySelector(`div.app__wrapper > div.sidebar > nav.sidebar__bar > a[href="/subscriptions"]`)?.style?.setProperty("display", "none");
    })
    .on("eb:session.login", () => {
        document.getElementById("userMenuMyAccountLink").href = `/accounts/${EBSession.account.id}`;
        document.getElementById("userMenuEmailText").innerText = EBSession.account.email;
        //document.getElementById("indexPortalName").innerText = Session.tenant.name;

    })
    .on("eb:session.logout", () => {
        document.getElementById("userMenuEmailText").innerText = "";
        //document.getElementById("indexPortalName").innerText = "";
        if (new URL(window.location.href).pathname.startsWith("/login") == false) LZSPARouter.navigate(`/login?redirect=${encodeURIComponent(new URL(window.location.href).pathname)}`);
    })
    .on("eb:session.initialized", () => {
        LZSPARouter
            .on("lzspa:router.start", (event) => {
                //console.log("testing");
            })
            .on("lzspa:router.end", (event) => {
                document.getElementById("loadingSpinner").style.visibility = (activeAPICalls > 0 ? "visible" : "hidden");
                document.querySelector("#sidebar").style.display = "none";
                document.querySelector("#usermenu").style.display = "none";
            })
            .on("lzspa:router.notfound", (event) => {
                LZSPARouter.navigate("/");
            })
            .on("lzspa:router.accessdenied", (event) => {
                if (!EBSession.valid)
                    LZSPARouter.navigate("/login?redirect=" + encodeURIComponent(event.detail.url));
                else
                    LZSPARouter.navigate("/");
            })
            .initialize({
                routes: [
                    { path: "/login",                           component: () => import("./components/login") },
                    { path: "/forgot",                          component: () => import("./components/forgot-password") },
                    { path: "/dashboard",                       component: () => import("./components/dashboard"),                  canActivate: () => EBSession.valid },
                    { path: "/accounts",                        component: () => import("./components/account-list"),               canActivate: () => EBSession.valid },
                    { path: "/accounts/:account",               component: () => import("./components/account-detail"),             canActivate: () => EBSession.valid },
                    { path: "/events",                          component: () => import("./components/event-list"),                 canActivate: () => EBSession.valid },
                    { path: "/events/create",                   component: () => import("./components/event-create"),               canActivate: () => EBSession.valid },
                    { path: "/events/:event/console",           component: () => import("./components/event-console"),              canActivate: () => EBSession.valid },
                    { path: "/events/:event",                   component: () => import("./components/event-detail"),               canActivate: () => EBSession.valid },
                    { path: "/registrants",                     component: () => import("./components/registrant-list"),            canActivate: () => EBSession.valid },
                    { path: "/registrants/:registrant",         component: () => import("./components/registrant-detail"),          canActivate: () => EBSession.valid },
                    { path: "/pages",                           component: () => import("./components/page-list"),                  canActivate: () => EBSession.valid },
                    { path: "/pages/:page",                     component: () => import("./components/page-detail"),                canActivate: () => EBSession.valid },
                    { path: "/subscriptions",                   component: () => import("./components/subscription-list"),          canActivate: () => EBSession.valid },
                    { path: "/subscriptions/:subscription",     component: () => import("./components/subscription-detail"),        canActivate: () => EBSession.valid },
                    { path: "/tags",                            component: () => import("./components/tag-list"),                   canActivate: () => EBSession.valid },
                    { path: "/tasks",                           component: () => import("./components/task-list"),                  canActivate: () => EBSession.valid },
                    { path: "/templates",                       component: () => import("./components/template-list"),              canActivate: () => EBSession.valid },
                    { path: "/templates/:template",             component: () => import("./components/template-detail"),            canActivate: () => EBSession.valid },
                    { path: "/tenants",                         component: () => import("./components/tenant-list"),                canActivate: () => EBSession.valid },
                    { path: "/tenants/create",                  component: () => import("./components/tenant-create"),              canActivate: () => EBSession.valid },
                    { path: "/tenants/:tenant",                 component: () => import("./components/tenant-detail"),              canActivate: () => EBSession.valid },
                    { path: "/",                                component: () => import("./components/dashboard"),                  canActivate: () => EBSession.valid },
                ],
                element: document.body.querySelector("main"),
                debug: true
            });
    })
    .initialize();