export class Modal extends EventTarget {

    static alert(message, title = "Alert") {
        return new Promise((resolve, reject) => {
            new Modal({
                title: title,
                size: "sm",
                static: true,
                content: message,
                controls: [
                    { action: "close", label: "OK", ariaLabel: "Ok" }
                ]
            })
            .on("eb:modal.close.click", (event) => { resolve(undefined); event.target.close(); })
            .on("eb:modal.control.close.click", (event) => { resolve(undefined); event.target.close(); })
            .show();
        });
    }

    static prompt(message, title = "Input", declineText = "Cancel", acceptText = "Ok") {
        return new Promise((resolve, reject) => {
            new Modal({
                title: title,
                size: "sm",
                static: true,
                content: `<div class="control">
                    <label for="promptInput">${message}</label>
                    <div>
                        <input type="text" id="promptInput">
                    </div>
                </div>`,
                controls: [
                    { action: "decline", label: declineText, ariaLabel: declineText },
                    { action: "accept", label: acceptText, ariaLabel: acceptText },
                ]
            })
            .on("eb:modal.close.click", (event) => { resolve(undefined); event.target.close(); })
            .on("eb:modal.control.decline.click", (event) => { resolve(undefined); event.target.close(); })
            .on("eb:modal.control.accept.click", (event) => { resolve(event.target.content.querySelector("input").value); event.target.close(); })
            .show();
        });
    }

    static confirm(message, title = "Confirm", declineText = "No", acceptText = "Yes") {
        return new Promise((resolve, reject) => {
            const instance = new Modal({
                title: title,
                size: "sm",
                static: true,
                content: message,
                controls: [
                    { action: "decline", label: declineText, ariaLabel: declineText },
                    { action: "accept", label: acceptText, ariaLabel: acceptText },
                ]
            });
            instance.on("eb:modal.close.click", (event) => { resolve(false); event.target.close(); });
            instance.on("eb:modal.control.decline.click", (event) => { resolve(false); event.target.close(); });
            instance.on("eb:modal.control.accept.click", (event) => { resolve(true); event.target.close(); });
            instance.show();
            instance.control("accept").focus();
        });
    }

    static choose(opts) {
        return new Promise((resolve, reject) => {
            new Modal({
                title: opts.title || "Choose",
                size: "sm",
                static: true,
                content: `<div class="control">
                    <label for="promptInput">${opts.message || "Choose an option"}</label>
                    <div>
                        <select>
                            ${(opts.options || []).map(o => `<option value="${o.value || o}">${o.displayText || o.label || o}</option>`)}
                        </select>
                    </div>
                </div>`,
                controls: [
                    { action: "decline", label: opts.declineText || "Cancel", ariaLabel: opts.declineText || "Cancel" },
                    { action: "accept", label: opts.acceptText || "Select", ariaLabel: opts.acceptText || "Select" },
                ]
            })
            .on("eb:modal.close.click", (event) => { resolve(undefined); event.target.close(); })
            .on("eb:modal.control.decline.click", (event) => { resolve(undefined); event.target.close(); })
            .on("eb:modal.control.accept.click", (event) => { resolve(event.target.content.querySelector("select").value); event.target.close(); })
            .show();
        });
    }

    #element;

    get element() { return this.#element; }
    get title() { return this.#element.children[1].children[0].children[0].innerHTML; }
    set title(value) { this.#element.children[1].children[0].children[0].innerHTML = value; }
    get content() { return this.#element.querySelector("div.modal__modal__content"); }
    set content(value) { return this.#element.querySelector("div.modal__modal__content").innerHTML = value; }
    get contentElement() { return this.#element.querySelector("div.modal__modal__content"); }
    get contentElementHTML() { return this.#element.querySelector("div.modal__modal__content").innerHTML; }
    set contentElementHTML(value) { return this.#element.querySelector("div.modal__modal__content").innerHTML = value; }
    control(action) { return this.#element.querySelector(`div.modal__modal__footer button[eb-action="${action}"]`); }
    get controls() { return this.#element.querySelectorAll("div.modal__modal__footer button[eb-action]"); }
    
    constructor(opts = {}) {
        super();
        
        this.#element = document.body.appendChild(document.createElement("div"));
        this.#element.className = "modal";
        if (opts.size) this.#element.classList.add(`modal--${opts.size}`);

        const backdropElement = this.#element.appendChild(document.createElement("div"));
        backdropElement.className = "modal__backdrop";
        if (opts.static != true)
            backdropElement.addEventListener("click", () => {
                if (this.dispatchEvent(new CustomEvent("eb:modal.backdrop.click")))
                    this.dispose();
            });

        const modalElement = this.#element.appendChild(document.createElement("div"));
        modalElement.className = "modal__modal";
        modalElement.setAttribute("aria-label", opts.title || "");
        modalElement.tabIndex = -1;
        
        const headerElement = modalElement.appendChild(document.createElement("div"));
        headerElement.className = "modal__modal__header";
        headerElement.innerHTML = `<div>${opts.title || ""}</div><button type="button" aria-label="Close"><svg data-src="/assets/images/x-lg.svg"></svg></button>`;
        headerElement.children[1].addEventListener("click", () => {
            if (this.dispatchEvent(new CustomEvent("eb:modal.close.click")))
                this.dispose();
        });

        const contentElement = modalElement.appendChild(document.createElement("div"));
        contentElement.className = "modal__modal__content";
        contentElement.innerHTML = opts.content || "";

        const footerElement = modalElement.appendChild(document.createElement("div"));
        footerElement.className = "modal__modal__footer";
        for (let control of (opts?.controls || [])) {
            const buttonElement = footerElement.appendChild(document.createElement("button"));
            buttonElement.className = control.className || "";
            buttonElement.setAttribute("role", "button");
            buttonElement.setAttribute("aria-label", control.ariaLabel);
            buttonElement.setAttribute("eb-action", control.action);
            if (control.disabled != undefined && control.disabled.toString().toLowerCase() == "true")
                buttonElement.setAttribute("disabled", "");
            if (control.visible != undefined && control.visible.toString().toLowerCase() == "false")
                buttonElement.style.setAttribute("display", "none");
            buttonElement.innerHTML = `${control.icon != undefined ? `<svg data-src="${control.icon}"></svg>` : ""}${control.label}`;
            buttonElement.addEventListener("click", (event) => this.dispatchEvent(new CustomEvent(`eb:modal.control.${event.target.getAttribute("eb-action")}.click`)));
        }
    }

    on(type, callback, options) {
        this.addEventListener(type, callback, options);
        return this;
    }

    show() {
        this.#element.style.setProperty("display", "block");
        //document.addEventListener("keydown", (event) => this.#tabTrap(event, this.#element.children[1]));
        this.#element.querySelector(`button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])`).focus();
    }

    hide() {
        this.#element.style.removeProperty("display");
        //document.removeEventListener("keydown", (event) => this.#tabTrap(event, this.#element.children[1]));
    }

    close() {
        this.dispose();
    }

    dispose() {
        this.#element.remove();
    }

    #tabTrap(e, element) {
        let isTabPressed = e.key === 'Tab' || e.keyCode === 9;
        if (!isTabPressed)
            return;

        if (element.contains(document.activeElement) == false) {
            element.querySelector(`button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])`).focus();
            e.preventDefault();
        }
    }
}
